body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-navbar {
  background-color: #1d1427;
  color: white;
}

.bg-footer {
  background-color: #1d1427;
  color: white;
}

.bg-footer-link:hover {
  color: white;
}

.unlock-page_card {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.unlock-page_title.mb-4 {
  font-weight: bold !important;
}

.unlock-page_title.mb-5 {
  font-weight: bold !important;
}

.unlock-page_description {
  font-weight: bold;
}

.btn-link-color {
  color: white !important;
}

.cannotPlay {
  content: url('assets/img/cannot-play.gif');
  max-width:30%;
  max-height:30%;
}

.pandamoniumHypey {
  content: url('assets/img/pandamonium-hypey.jpg');
  max-width:50%;
  max-height:50%;
}

.startButton {
  content: url('assets/img/start-button.png');
  max-width:30%;
  max-height:30%;
}

.earlyAccessMatrix {
  content: url('assets/img/early-access-matrix.gif');
  max-width:30%;
  max-height:30%;
}

.comingSoon {
  content: url('assets/img/samurai-panda-monium2.jpg');
  max-width:30%;
  max-height:30%;
}

/*###Desktops, big landscape tablets and laptops(Large, Extra large)####*/
@media screen and (min-width: 1024px) {
  /*Style*/
  .magicBackground {
    background: url('assets/img/magic-bg-d.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .arcadeMachine {
    content: url('assets/img/arcade-machine-login-d.png');
    max-width:90%;
    max-height:90%;
  }
}

/*###Tablet(medium)###*/
@media screen and (min-width : 768px) and (max-width : 1023px) {
  /*Style*/
  .magicBackground {
    background: url('assets/img/magic-bg-t.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .arcadeMachine {
    content: url('assets/img/arcade-machine-login-t.png');
    max-width:80%;
    max-height:80%;
  }
}

/*### Smartphones (portrait and landscape)(small)### */
@media screen and (min-width : 0px) and (max-width : 767px) {
  /*Style*/
  .magicBackground {
    background: url('assets/img/magic-bg-m.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .arcadeMachine {
    content: url('assets/img/arcade-machine-login-m.png');
    max-width:100%;
    max-height:100%;
  }

  .comingSoon {
    content: url('assets/img/samurai-panda-monium2.jpg');
    max-width:50%;
    max-height:50%;
  }
}
